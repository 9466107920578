export default class GoogleSheetsOperation {
  constructor() {
    this.items = [
      {
        id: 1,
        label: 'enum.google_sheets.operations.add',
        value: 'add'
      },
      {
        id: 2,
        label: 'enum.google_sheets.operations.get',
        value: 'get'
      },
      {
        id: 3,
        label: 'enum.google_sheets.operations.delete',
        value: 'delete'
      },
      {
        id: 4,
        label: 'enum.google_sheets.operations.update',
        value: 'update'
      },
      {
        id: 5,
        label: 'enum.google_sheets.operations.add_page',
        value: 'addpage'
      },
      {
        id: 6,
        label: 'enum.google_sheets.operations.insert_row',
        value: 'insertrow'
      },
      {
        id: 7,
        label: 'enum.google_sheets.operations.insert_col',
        value: 'insertcolumn'
      },
      {
        id: 8,
        label: 'enum.google_sheets.operations.delete_col',
        value: 'deletecolumn'
      },
      {
        id: 9,
        label: 'enum.google_sheets.operations.delete_row',
        value: 'deleterow'
      }
    ];
  }
}